<template>
  <div class="checkbox relative">
    <input
      class="custom-checkbox"
      type="checkbox"
      :id="id"
      v-model="model"
      :value="value"
      :disabled="disabled"
      @change="change"
    />
    <label :for="id" class="font-sm cursor-pointer font-lato text-gray-700">
      {{ label }}
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  id: { type: String },
  modelValue: { type: [Array, Boolean, String] },
  value: { type: [Boolean, Object, String] },
  label: { type: String },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue', 'change']);

function change(e) {
  emit('change', e.target.value);
}

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped>
/* для элемента input c type="checkbox" */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #BCBCBC;
  border-radius: 4px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  margin-top: 1px;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border: 1px solid #b7b7b7;
}

/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked + label::before {
  border: 1px solid #b7b7b7;
  background-image: url('/assets/img/arrow.png');
  background-size: 22px;
}

/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled + label::before {
  background-color: #e9ecef;
}
</style>